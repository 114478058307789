<template>
	<v-card class="ma-3 elevation-4" width="900" v-if="research_questionnaire">
		<v-card-text class="pb-0">
			<div class="mt-2 wr_blue_1--text font-weight-bold title">
				{{ $t("app.my_research_questionl") }}
			</div>
		</v-card-text>

		<!-- Otherwise, display form -->
		<v-card-text class="py-0">
			<v-form ref="myResearchQuestionForm">
				<v-text-field
					outlined
					class="mt-5"
					color="greenAccent2"
					background-color="white"
					v-model="research_questionnaire.answer"
					@blur="validateAndProceed()"
					:rules="
						research_questionnaire.question.required === 'true'
							? [rules.required]
							: []
					"
					:readonly="is_final_questionnaire_submitted"
				></v-text-field>
			</v-form>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import rules from "@/utils/validation";

export default {
	name: "ChooseQuestionMyResearchQuestion",

	data() {
		return {
			rules,
			research_questionnaire: null,
			is_final_questionnaire_submitted: false,
		};
	},

	created() {
		this.readyToListenEvents();
	},

	mounted() {
		this.init();
	},

	computed: {
		...mapState({
			current_step: (state) => state.process.current_step,
		}),
	},

	methods: {
		async init() {
			try {
				this.$loader.start();
				// Get research questionnaire
				await this.getResearchQuestionnaire();
				// Check if final questionnaire is submitted
				await this.isFinalQuestionnaireSubmited();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async readyToListenEvents() {
			this.$eventBus.$on("crq-validate-research-question", async (payload) => {
				let valid = await this.validateAndProceed();

				// If all set well, redirect to the next
				if (valid) {
					this.$eventBus.$emit("crq-redirect-next");
				}
			});
		},

		async getResearchQuestionnaire() {
			let response = await this.$store.dispatch("questionnaire/get", {
				questionnaire_name: this.$defines.QUESTIONNAIRE.q51,
			});
			if (response) {
				this.research_questionnaire = response.answers[0];
			}
		},

		validateAndProceed() {
			try {
				if (!this.$refs.myResearchQuestionForm.validate()) {
					throw new Error("app.ntfy.err.fill_required_fields", {
						cause: "werCustom",
					});
				}

				this.$emit("submit-my-research-question", this.research_questionnaire);
				return true;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async isFinalQuestionnaireSubmited() {
			let response = await this.$store.dispatch("questionnaire/get", {
				questionnaire_name: this.$defines.QUESTIONNAIRE.q51,
			});

			this.is_final_questionnaire_submitted =
				response.status ===
				this.$defines.QUESTIONNAIRE_STATUS_WAIT_FOR_APPROVAL_STRING;
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */

		this.$eventBus.$off("crq-validate-research-question");
	},
};
</script>
