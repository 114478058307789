<template>
	<v-card v-if="board" height="100%" id="wizard-map-wr">
		<!-- Primary Actions -->
		<AppActionsTheme
			type="primary"
			:actions="primaryActions"
			@handle-function-call="handleFunctionCall"
		>
		</AppActionsTheme>

		<!-- Required Actions -->
		<AppActionsTheme
			v-if="isBoardOwner"
			type="required"
			:actions="requiredActions"
			@handle-function-call="handleFunctionCall"
		>
		</AppActionsTheme>

		<!-- Zoom Actions -->
		<ZoomController el="#downloadable-container-wr"></ZoomController>

		<!-- Container to operate on -->
		<v-card
			v-if="board.data.length"
			outlined
			id="downloadable-container-wr"
			height="100%"
		>
			<!-- Adding connected arrows -->
			<Arrows
				v-if="isArrowSteps && arrows.length"
				:is_full_screen="is_full_screen"
				@arrow-handler="arrowHandler"
				@remove-arrow="removeArrow"
			></Arrows>

			<!-- In order to keep nodes position same in all cases, Each parent container
				 of vue resizable should placed at the top left so all nodes (vue resizable) can
				 start their dimensions calculation from top left
		 -->

			<vue-resizable
				v-for="(item, index) in nodes"
				:key="index"
				class="resizable resizable-wr"
				:id="`resize-${item.id}`"
				:fitParent="true"
				ref="resizableComponent"
				dragSelector=".drag-container-wr"
				:active="['r', 'rb', 'b', 'lb', 'l', 'lt', 't', 'rt']"
				:width="item.width"
				:height="item.height"
				:left="item.left"
				:top="item.top"
				@resize:move="eHandler(item.id, $event)"
				@resize:end="eHandler(item.id, $event)"
				@drag:end="eHandler(item.id, $event)"
			>
				<div class="drag-container-wr">
					<!--
						Each node's v-menu is attached to node itself
						so it can open in full screen mode too
					 -->
					<v-menu
						offset-y
						:attach="is_full_screen ? `#resize-${item.id}` : false"
						:close-on-content-click="false"
					>
						<template v-slot:activator="{ on, attrs }">
							<div :id="item.id">
								<!-- dark attribute? To change the color of placeholder -->
								<v-textarea
									dark
									solo
									flat
									v-on="on"
									no-resize
									hide-details
									v-bind="attrs"
									v-model="item.text"
									v-debounce:1s="manageTyping(item.id)"
									:height="item.height"
									:color="item.color"
									:background-color="item.background"
									:placeholder="
										item.is_title_node
											? $t('app.placeholder.type_title_here')
											: $t('app.placeholder.type_text_here_and_drag', {
													box_no: index + 1,
											  })
									"
									:class="[
										{
											'elevation-0': true,
											'font-weight-bold': item.bold,
											'font-italic': item.italic,
											[item.font]: true,
										},
									]"
								>
									<v-icon
										v-if="
											current_step && current_step.name === 'selectFavNodes'
										"
										slot="append"
										:color="
											item.fav
												? 'error'
												: item.background === 'black'
												? 'white'
												: 'black'
										"
										@click="
											updateStyle(item, {
												attribute: 'fav',
												value: !item.fav,
											})
										"
										>{{
											$vuetify.icons.values[
												item.fav ? "heart" : "heart_outline"
											]
										}}
									</v-icon>
								</v-textarea>
							</div>
						</template>

						<Editor
							:item="item"
							:is_full_screen="is_full_screen"
							@delete-node="deleteNode(item.id)"
							@update-style="updateStyle(item, $event)"
							@prepare-attachment-process="prepareAttachmentProcess(item)"
						></Editor>
					</v-menu>
				</div>
			</vue-resizable>
		</v-card>

		<!-- Attachment dialog -->
		<AttachmentDialog
			v-model="attachment_dialog"
			:dialog="attachment_dialog"
			:node="selected_node"
			attach="#wizard-map-wr"
			@close="attachment_dialog = false"
			@confirm="setAttachment"
		></AttachmentDialog>

		<!-- Sharing Dialog box -->
		<ShareDialog
			v-model="share_dialog"
			:dialog="share_dialog"
			:uuid="board.uuid"
			@close="share_dialog = false"
			@confirm="share_dialog = false"
		></ShareDialog>

		<!-- Confirmation box for delete operation -->
		<TheConfirmation
			v-model="delete_dialog"
			:dialog="delete_dialog"
			@cancel="delete_dialog = false"
			@confirm="executeDeletion()"
		></TheConfirmation>
	</v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
	validateNodesStatus,
	validateFavNodes,
} from "@/utils/helpers/whiteboard";
import TheConfirmation from "@/components/layout/TheConfirmation";
import AttachmentDialog from "@/components/shared/tools/wizardMindmap/AttachmentDialog.vue";
import { collaborationCommonInfoMixin } from "@/mixins/collaboration/commonInfoMixin.js";
import { collaborationFirebaseMixin } from "@/mixins/collaboration/firebaseMixin.js";
import Editor from "@/components/shared/tools/wizardMindmap/Editor.vue";
import Arrows from "@/components/research/modules/defineQuestion/stages/chooseTopic/alternatives/wizardMindmap/Arrows.vue";
import {
	findFromArray,
	filterArray,
	findElIndex,
	isArrayHavingItem,
	generateRandomString,
} from "@/utils/helpers";
import { questionnaireHelper } from "@/utils/helpers/questionnaire";
import ZoomController from "@/components/plugins/ZoomController.vue";

export default {
	name: "ChooseTopicAltWizardMindmapBoard",

	mixins: [collaborationFirebaseMixin, collaborationCommonInfoMixin],

	data() {
		return {
			attachment_dialog: false,
			selected_node: {},
			is_full_screen: false,
		};
	},

	components: {
		AttachmentDialog,
		Editor,
		Arrows,
		ZoomController,
	},

	created() {
		this.readyToListenEvents();
	},

	mounted() {
		this.validateTitleNode();
	},

	watch: {
		"current_step.value": function(newVal, oldVal) {
			if (newVal && newVal !== oldVal) {
				this.applyStylesManually();
			}
		},
	},

	computed: {
		...mapGetters({
			isCurrentStepCompleted: "process/isCurrentStepCompleted",
		}),

		...mapState({
			arrows: (state) => state.mindmap.arrows,
		}),

		isFirstStep() {
			return this.current_step && this.current_step.name === "prepareTitleNode";
		},

		isArrowSteps() {
			if (!this.current_step) return false;

			return isArrayHavingItem(
				["prepareConnections", "enrichMap", "selectFavNodes"],
				this.current_step.name,
			);
		},

		isSelectFavNotesStep() {
			return this.current_step && this.current_step.name === "selectFavNodes";
		},

		primaryActions() {
			return [
				{
					icon: "rectangle_outline",
					tooltip: "app.add_node",
					method: "addNodes",
					condition: true,
					// !this.isCurrentStepCompleted,
				},
				{
					icon: "arrow_down",
					tooltip: "app.arrow",
					method: "addArrow",
					// Arrows should only visible after 3rd step and uncompleted step.
					condition: this.isArrowSteps,
					// && !this.isCurrentStepCompleted,
				},
				{
					icon: this.is_full_screen ? "exit_full_screen" : "full_screen",
					tooltip: this.is_full_screen
						? "app.exit_full_screen"
						: "app.full_screen",
					method: "toggleScreenMode",
					condition: true,
				},
				// {
				// 	icon: "delete",
				// 	tooltip: "app.delete_board",
				// 	method: "showConfirmationDialog",
				// 	condition: this.isBoardOwner,
				// },
				{
					icon: "download",
					tooltip: "app.download",
					method: "downloadObject",
					condition: true,
				},
			];
		},

		nodes() {
			let result = [];
			this.board.data.forEach((item) => {
				/**
				 * If step is first then only root node should display, otherwise
				 * for every step show all nodes.
				 */
				if ((this.isFirstStep && item.is_title_node) || !this.isFirstStep) {
					result.push(item);
				}
			});
			return result;
		},
	},

	methods: {
		readyToListenEvents() {
			document.addEventListener("fullscreenchange", (event) => {
				// document.fullscreenElement will point to the element that
				// is in fullscreen mode if there is one. If there isn't one,
				// the value of the property is null.
				this.is_full_screen = document.fullscreenElement;
			});
			this.$eventBus.$on("wmm-validate-nodes", async (payload) => {
				await validateNodesStatus(this.board.data, this.isFirstStep);
			});
			this.$eventBus.$on("wmm-validate-nodes-on-back", async (payload) => {
				await validateNodesStatus(this.board.data, this.isFirstStep, "back");
			});
			this.$eventBus.$on("wmm-validate-fav-nodes", async (payload) => {
				await validateFavNodes(this.board.data);
			});
		},

		getRootNode() {
			return filterArray(this.board.data, "is_title_node", false);
		},

		isTitleNodePresent() {
			return findFromArray(this.board.data, "is_title_node", true);
		},

		async validateTitleNode() {
			try {
				// Check if title node is present or not
				if (!this.isTitleNodePresent()) {
					// If title node not present then create with choose theme question's answer
					let result = await questionnaireHelper.getChosenTheme();
					if (!result) return;

					this.addNodes(result.answer);
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},

		applyStylesManually() {
			// Update style manually after DOM updated.
			this.$nextTick(() => {
				this.board.data.forEach((item) => {
					let el = document.getElementById(item.id);
					if (el) {
						let text_field = el.getElementsByTagName("textarea")[0];
						text_field.style["fontFamily"] = item["fontFamily"];
						text_field.style["color"] = this.$defines.THEME[item["color"]];
					}
				});
			});
		},

		prepareAttachmentProcess(node) {
			try {
				this.$loader.start();

				this.selected_node.id = node.id;
				this.selected_node.text = node.text;
				this.selected_node.attachment = node.attachment;

				this.attachment_dialog = true;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		toggleScreenMode() {
			let el = document.getElementById("wizard-map-wr");
			if (el) {
				if (this.is_full_screen) {
					return document.exitFullscreen();
				}
				el.requestFullscreen();
				el.style.background = "#fff";
			}
		},

		addArrow() {
			try {
				let arrow = {
					id: generateRandomString(),
					x: this.$vuetify.rtl ? 167 : 100,
					y: 100,
					w: 40,
					h: 40,
					angle: 0,
				};
				this.$store.commit("mindmap/ADD_ARROW", {
					arrow,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		arrowHandler(payload) {
			try {
				this.$store.commit("mindmap/UPDATE_ARROW", {
					arrow_id: payload.arrow_id,
					event: payload.event,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		removeArrow(arrow_id) {
			try {
				this.$loader.start();

				this.$store.commit("mindmap/REMOVE_ARROW", {
					arrow_id,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async updateStyle(item, payload) {
			try {
				item[payload.attribute] = payload.value;

				// We need to assign it manually because bind style is not working in textarea.
				if (isArrayHavingItem(["color", "fontFamily"], payload.attribute)) {
					let el = document.getElementById(item.id);
					if (el) {
						let text_field = el.getElementsByTagName("textarea")[0];
						text_field.style["fontFamily"] = payload.value;
						text_field.style["color"] = payload.value;
					}
				}

				await this.updateAtFirebase(payload.attribute, {
					id: item.id,
					[payload.attribute]: payload.value,
				});

				// Save modifications to database
				await this.updateAtDatabase();
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async setAttachment(payload) {
			try {
				this.$loader.start();

				["text", "attachment"].forEach(async (attribute) => {
					await this.updateAtFirebase(attribute, {
						id: payload.node.id,
						[attribute]: payload.node[attribute],
					});
				});

				// Save modifications to database
				await this.updateAtDatabase();

				this.selected_node = {};
				this.attachment_dialog = false;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async addNodes(text = "") {
			try {
				// Check if any title node is already present.
				let title_node_present = this.isTitleNodePresent();

				// If step is second and title node is present then do not allow to add more
				if (this.isFirstStep && title_node_present) {
					throw new Error("app.ntfy.err.only_title_node_allowed", {
						cause: "werCustom",
					});
				}

				let obj = {};
				obj.id = generateRandomString();
				obj.text = text;
				obj.height = title_node_present ? 50 : 70;
				obj.width = title_node_present ? 260 : 360;
				obj.left = 700;
				obj.top = 300;
				obj.eventName = obj.attachment = "";
				obj.bold = obj.italic = obj.fav = false;
				obj.color = "white";
				obj.background = title_node_present ? "warning" : "info";
				obj.font = title_node_present ? "title" : "headline";
				obj.fontFamily = "sans-serif";
				obj.is_title_node = this.isFirstStep;

				await this.updateAtFirebase("add_node", {
					id: obj.id,
					data: obj,
				});

				// Save modifications to database
				await this.updateAtDatabase();

				// update style manually of newly added node.
				this.updateStyle(obj, {
					attribute: "color",
					value: obj.color,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async deleteNode(node_id) {
			try {
				this.$loader.start();

				let node_to_deleted = findFromArray(this.board.data, "id", node_id);
				// Do not allow removing root nodes once it created.
				if (node_to_deleted.is_title_node) {
					throw new Error("app.ntfy.err.title_node_delete", {
						cause: "werCustom",
					});
				}

				await this.updateAtFirebase("remove_node", {
					id: node_id,
				});

				// Save modifications to database
				this.updateAtDatabase();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("wmm-validate-nodes");
		this.$eventBus.$off("wmm-validate-nodes-on-back");
		this.$eventBus.$off("wmm-validate-fav-nodes");
	},
};
</script>

<style scoped>
/* Because we need auto width and vuetify applies max width */
>>> .v-menu__content {
	max-width: none;
}

>>> .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
	text-align: center !important;
}

>>> .v-input--selection-controls {
	margin-top: 0px;
}
</style>

<style lang="scss">
@import "@/assets/styles/buttonGroup.scss";
</style>
