<template>
	<v-card
		v-if="shouldDisplay && question_focus"
		width="500"
		height="75"
		class="overflow-hidden"
	>
		<v-card-text align="center">
			<span class="wr_blue_1--text font-weight-bold subtitle-1"
				>{{ $t("app.question_focus") }}:&ensp;</span
			>
			<span class="black--text font-weight-bold subtitle-1">{{
				question_focus
			}}</span>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import { isArrayHavingItem } from "@/utils/helpers";

export default {
	name: "ChooseQuestionFocusQuestion",

	data() {
		return {
			question_focus: null,
		};
	},

	computed: {
		...mapState({
			current_step: (state) => state.process.current_step,
		}),

		shouldDisplay() {
			if (!this.current_step) return false;

			return !isArrayHavingItem(
				["writeProsAndCons", "reviewSelectedNotes", "questionnaire"],
				this.current_step.name,
			);
		},
	},

	mounted() {
		this.getQuestionFocus();
	},

	methods: {
		async getQuestionFocus() {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch("questionnaire/get", {
					questionnaire_name: this.$defines.QUESTIONNAIRE.q30,
				});

				if (response) {
					let res = response.answers.find((answer) => {
						// Order 1 is of the research qustion's order.
						return answer.question.order === 1;
					});
					if (!res) {
						throw new Error("app.ntfy.err.question_not_found", {
							cause: "werCustom",
						});
					}
					this.question_focus = res.answer;
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
