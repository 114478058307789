<template>
	<v-card
		v-if="current_step && current_step.name === 'addNotesWithTimer'"
		width="170"
		height="75"
		:class="['ms-15', { 'card-wr': isRunning, 'border-wr': autoStopped }]"
		rounded
	>
		<v-card-text
			align="center"
			class="py-5 headline wr_blue_1--text font-weight-bold"
		>
			<!-- If timer has been started -->
			<v-row no-gutters v-if="isRunning">
				<v-col class="pt-1" align="center">{{ currentTime }}</v-col>
				<v-col class="text-end">
					<v-icon
						x-large
						color="wr_blue_1"
						class="cursor-wr"
						@click="stopTimer()"
						>$vuetify.icons.values.pause</v-icon
					>
				</v-col>
			</v-row>

			<!-- If timer is not started yet -->
			<v-row no-gutters v-if="!isRunning && time">
				<v-col class="pt-1" align="center">{{ currentTime }}</v-col>
				<v-col class="text-end">
					<v-icon
						x-large
						color="wr_blue_1"
						class="cursor-wr"
						@click="startTimer()"
						>$vuetify.icons.values.play</v-icon
					>
				</v-col>
			</v-row>

			<!-- If timer has stopped automatically -->
			<v-row no-gutters v-if="autoStopped" @click="setUpTimer()">
				<v-col sm="9" class="pt-1 title font-weight-bold" align="center">{{
					$t("app.time_up")
				}}</v-col>
				<v-col class="text-end"
					><v-icon color="wr_blue_1" class="cursor-wr"
						>$vuetify.icons.values.bell</v-icon
					></v-col
				>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
	name: "Timer",

	data() {
		return {
			isRunning: false,
			time: null,
			timer: null,
			minutes: 0,
			seconds: 0,
			autoStopped: false,
		};
	},

	props: {
		time_limit: {
			required: true,
			type: Number,
		},
	},

	mounted() {
		this.setUpTimer();
	},

	computed: {
		...mapGetters({
			isCurrentStepCompleted: "process/isCurrentStepCompleted",
		}),

		...mapState({
			current_step: (state) => state.process.current_step,
		}),

		currentTime() {
			let time = this.time / 60;
			let minutes = parseInt(time);
			let seconds = Math.round((time - minutes) * 60);
			if (minutes.toString().length < 2) {
				minutes = "0" + minutes;
			}
			if (seconds.toString().length < 2) {
				seconds = "0" + seconds;
			}
			return minutes + ":" + seconds;
		},
	},

	methods: {
		setUpTimer() {
			try {
				this.autoStopped = false;
				this.time = this.time_limit * 60;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		startTimer() {
			try {
				// If current step is completed then do not allow to operate with timer.
				if (this.isCurrentStepCompleted) {
					return;
				}

				this.isRunning = true;
				this.autoStopped = false;

				if (!this.timer) {
					this.timer = setInterval(() => {
						if (this.time > 0) {
							this.time--;
						} else {
							clearInterval(this.timer);
							this.resetTimer();
							this.autoStopped = true;
						}
					}, 1000);
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},

		stopTimer() {
			try {
				this.isRunning = false;
				clearInterval(this.timer);
				this.timer = null;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		resetTimer() {
			this.stopTimer();
			this.time = 0;
			this.seconds = 0;
			this.minutes = 0;
		},
	},
};
</script>

<style scoped>
.card-wr {
	position: relative;
}

.card-wr:before,
.card-wr:after {
	content: "";
	display: block;
	position: absolute;
	background-color: transparent;
	pointer-events: none;
}

.card-wr:before {
	top: 0;
	right: 0;
	left: 50%;
	border-top: 2px solid var(--v-wr_blue_1-base);
	border-left: 2px solid var(--v-wr_blue_1-base);
}

.card-wr:after {
	top: 0%;
	left: 50%;
	right: 0;
	bottom: 0;
	border-bottom: 2px solid var(--v-wr_blue_1-base);
	border-right: 2px solid var(--v-wr_blue_1-base);
}

.border-wr {
	border: 2px solid var(--v-wr_blue_1-base) !important;
}
</style>
