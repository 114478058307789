<template>
	<v-card v-if="questionnaire" flat class="wr_grey_1">
		<!-- Display research question at the top -->
		<ResearchQuestion></ResearchQuestion>

		<v-card-text class="mt-2">
			<!-- If questionnaire is in draft mode then show form -->
			<v-form
				ref="chooseQuestionQuestionnaireForm"
				v-if="!isQuestionnaireApproved"
			>
				<FormGenerator></FormGenerator>
			</v-form>

			<!-- else show only answers of form -->
			<FormResult v-else></FormResult>
		</v-card-text>

		<!-- Show finish source documentation  -->
		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			current_step="app.choose_question.title"
			@close="dialog = false"
		></StageFinishDialog>
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import FormResult from "@/components/plugins/formResult/Index";
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";
import { formValidationMixin } from "@/mixins/questionnaire/formValidaionMixin.js";
import FormGenerator from "@/components/plugins/formGenerator/Index";
import ResearchQuestion from "@/components/research/modules/_common/ResearchQuestion.vue";
import { questionnaireHelper } from "@/utils/helpers/questionnaire.js";
import { notificationWriteMixin } from "@/mixins/notificationsMixin";

export default {
	name: "ChooseQuestionSummary",

	mixins: [formValidationMixin, notificationWriteMixin],

	data() {
		return {
			dialog: false,
		};
	},

	components: {
		FormGenerator,
		FormResult,
		StageFinishDialog,
		ResearchQuestion,
	},

	created() {
		this.readyToListenEvents();
	},

	mounted() {
		this.getQuestions();
	},

	computed: {
		...mapState({
			questionnaire_id: (state) => state.questionnaire.list.id,
			questionnaire: (state) => state.questionnaire.list,
		}),

		isQuestionnaireApproved() {
			return (
				this.questionnaire.status ==
				this.$defines.QUESTIONNAIRE_STATUS_WAIT_FOR_APPROVAL_STRING
			);
		},
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("crq-submit-summary", (payload) => {
				this.submitQuestionnaire();
			});
			this.$eventBus.$on("crq-finish-process", async (payload) => {
				await this.onFinishProcess();
			});
		},

		async notifyToMentor() {
			// Get the research question
			let research_question = await questionnaireHelper.getResearchQuestion();
			// If the answer is given, send it to mentor.
			if (research_question && this.userHasMentor) {
				let response = await this.$store.dispatch("notification/send", {
					recipient: "mentor",
					msg_title: this.$t("app.my_research_questionl"),
					msg_content: research_question.answer,
				});
				// Now, Write this message to firebase
				await this.writeNotification(response);
			}
		},

		async onFinishProcess() {
			try {
				this.$loader.start();

				// Send the research question to mentor
				await this.notifyToMentor();

				// Open the finish dialog
				this.dialog = true;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getQuestions() {
			try {
				this.$loader.start();

				await this.$store.dispatch("questionnaire/list", {
					questionnaire_name: this.$defines.QUESTIONNAIRE.q50,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async submitQuestionnaire() {
			try {
				this.$loader.start();

				let valid = await this.isFormValid("chooseQuestionQuestionnaireForm");
				if (!valid) {
					throw new Error("app.ntfy.err.fill_required_fields", {
						cause: "werCustom",
					});
				}

				// Submit questionnaire
				await this.$store.dispatch("questionnaire/submit", {
					questionnaire_id: this.questionnaire_id,
					status: this.$defines.QUESTIONNAIRE_STATUS_WAIT_FOR_APPROVAL_STRING,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("crq-submit-summary");
		this.$eventBus.$off("crq-finish-process");
	},
};
</script>
