<template>
	<v-dialog :value="dialog" scrollable width="1000" persistent>
		<AppDialog
			:heading="{
				label: 'app.instructions',
			}"
			:show_cancel="false"
			@close="$emit('close')"
			@finish="$emit('close')"
		>
			<template v-slot:dialog-content>
				<v-container fill-height fluid class="wr_grey_1 instructions-wr">
					<v-row justify="center">
						<v-col align="center">
							<v-card width="500" class="instruction-card-wr">
								<v-carousel
									height="390"
									:show-arrows="true"
									cycle
									hide-delimiter-background
									:interval="4000"
								>
									<v-carousel-item
										v-for="(item, index) in instructions"
										:key="index"
									>
										<v-sheet color="white" height="100%">
											<v-card-text
												align="center"
												class="black--text font-weight-bold"
											>
												{{ $t(item.text) }}
											</v-card-text>

											<v-row class="mt-15">
												<v-col sm="4">
													<v-icon size="60" color="black">
														$vuetify.icons.values.cursor_outline
													</v-icon>
												</v-col>
												<v-col sm="3">
													<v-icon size="50" color="grey">
														{{
															$vuetify.icons.values[
																$vuetify.rtl ? "end_arrow" : "start_arrow"
															]
														}}
													</v-icon>
												</v-col>
												<v-col sm="5">
													<v-card
														v-if="index == 0"
														color="amberAccent1"
														flat
														width="110"
														height="110"
														class="overflow-hidden"
													>
														<v-card-text>
															{{
																$t("app.placeholder.type_your_question_here")
															}}
														</v-card-text>
													</v-card>

													<v-card width="120" v-else class="white">
														<v-card-text
															class="wr_blue_1--text body-2 font-weight-bold"
														>
															<span>{{ const_time }}</span> &ensp;
															<span
																><v-icon color="wr_blue_1"
																	>$vuetify.icons.values.play</v-icon
																></span
															>
														</v-card-text>
													</v-card>
												</v-col>
											</v-row>
										</v-sheet>
									</v-carousel-item>
								</v-carousel>
							</v-card>
						</v-col>
					</v-row>
					<v-row justify="center" class="mt-10">
						<AppButton
							label="app.ok_got_it"
							@click="$emit('close')"
						></AppButton>
					</v-row>
				</v-container>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import AppDialog from "@/components/ui/AppDialog.vue";

export default {
	name: "ChooseQuestionInstructions",

	props: {
		dialog: {
			required: true,
		},
	},

	data() {
		return {
			const_time: "10:00",
			instructions: [
				{
					text: "app.click_twice_to_create_note",
				},
				{
					text: "app.press_timer_when_ready",
				},
			],
		};
	},

	components: {
		AppDialog,
	},
};
</script>

<style scoped>
>>> .v-btn--active .mdi-circle::before {
	color: var(--v-error-base) !important;
}

>>> .v-carousel__controls {
	background: rgba(0, 0, 0, 0.1) !important;
}

>>> .v-window__prev button,
>>> .v-window__next button {
	width: 25px !important;
	height: 25px !important;
}

>>> .v-window__prev button i,
>>> .v-window__next button i {
	font-size: 20px !important;
}

.instruction-card-wr {
	border: 3px solid var(--v-greenAccent2-base) !important;
	box-shadow: 0 0 10px 1px var(--v-greenAccent2-base) !important;
}
</style>
