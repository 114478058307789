<template>
	<v-dialog :value="dialog" scrollable width="550px" persistent>
		<AppDialog
			:action="{
				label: 'app.redirect_to_my_board',
				event: 'proceed',
			}"
			:show_cancel="false"
			@close="$emit('close')"
			@proceed="closeDialogBox()"
		>
			<template v-slot:dialog-content>
				<v-row align="center" justify="center">
					<v-col cols="12" align="center">
						<Loading></Loading>
					</v-col>
					<v-col
						cols="12"
						class="headline black--text text-center font-weight-bold"
					>
						{{ $t("app.processing_request") }}
					</v-col>
					<v-col
						cols="12"
						class="subtitle-1 text-center pt-0"
						v-html="$t('app.board_owner_busy_in_other_steps')"
					>
					</v-col>
				</v-row>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import AppDialog from "@/components/ui/AppDialog.vue";
import Loading from "@/components/plugins/Loading.vue";

export default {
	name: "BoardOwnerBusyDialog",

	props: {
		dialog: {
			required: true,
			type: Boolean,
		},
	},

	components: {
		AppDialog,
		Loading,
	},

	methods: {
		closeDialogBox() {
			// Null the board id in state
			this.$store.commit("whiteboard/SET_CURRENT_BOARD_ID", {
				board_id: null,
			});

			// Push to current route without query param
			this.$router.push({
				name: this.$route.name,
			});

			// Reload the current route
			this.$router.go();
		},
	},
};
</script>
