<template>
	<div>
		<!-- choose tags for each note -->
		<v-row
			class="opacity-background-wr pa-2"
			v-if="shouldDisplayTags"
			no-gutters
		>
			<!-- Keep menu disabled always if step is "selectFavNotes"
					 No matter, this step is completed or not otherwise
					 disabled for every completed step.
			 -->
			<v-menu offset-y :disabled="current_step.name === 'selectFavNotes'">
				<template v-slot:activator="{ on, attrs }">
					<v-row no-gutters>
						<v-col>
							<!-- If no lable is selected then display tag icons -->
							<v-icon
								v-if="!note.label"
								v-bind="attrs"
								v-on="on"
								class="cursor-wr"
								>$vuetify.icons.values.tag_outline</v-icon
							>
							<!-- otherwise display respected labels -->
							<v-btn
								v-else
								dark
								x-small
								depressed=""
								v-bind="attrs"
								v-on="on"
								:color="
									note.label == $defines.OPEN_QUESTION_VALUE ? 'info' : 'error'
								"
								>{{
									note.label == $defines.OPEN_QUESTION_VALUE
										? $t("app.open_question")
										: $t("app.close_question")
								}}</v-btn
							>
						</v-col>
						<v-col
							v-if="current_step.name == 'selectFavNotes'"
							class="cursor-wr text-end"
							@click="$emit('update-fav-status')"
						>
							<v-icon :color="note.fav ? 'error' : ''">{{
								$vuetify.icons.values[note.fav ? "heart" : "heart_outline"]
							}}</v-icon>
						</v-col>
					</v-row>
				</template>

				<!-- Pop up for choose tags -->
				<v-list dense>
					<v-list-item>
						<v-row>
							<v-col>{{ $t("app.choose_tag") }}</v-col>
							<v-col>
								<v-btn
									dark
									color="info"
									x-small
									@click="$emit('mark-as-open-question')"
								>
									{{ $t("app.open_question") }}</v-btn
								>
							</v-col>
							<v-col>
								<v-btn
									color="error"
									dark
									x-small
									@click="$emit('mark-as-closed-question')"
								>
									{{ $t("app.close_question") }}</v-btn
								>
							</v-col>
						</v-row>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-row>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { isArrayHavingItem } from "@/utils/helpers";

export default {
	name: "ChooseQuestionNoteActivity",

	props: {
		note: {
			required: true,
			type: Object,
		},
	},

	computed: {
		...mapState({
			current_step: (state) => state.process.current_step,
		}),

		...mapGetters({
			isCurrentStepCompleted: "process/isCurrentStepCompleted",
		}),

		shouldDisplayTags() {
			return isArrayHavingItem(
				["chooseTagsForNotes", "interchangeTags", "selectFavNotes"],
				this.current_step.name,
			);
		},
	},
};
</script>
