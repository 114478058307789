import { store } from "@/store";
import { EventBus } from "@/plugins/event-bus";
import { filterArray, findFromArray } from "@/utils/helpers";
import { announce } from "@/plugins/announce";
import { loader } from "@/plugins/loader";
import { i18n } from "@/plugins/i18n";
import { DEFINES } from "@/utils/defines";

const prepareUnCompletedNotes = (arr, key, matcher) => {
	let uncompleted_notes = [];

	arr.forEach((item) => {
		let el = document.getElementById(item.id);
		if (el) {
			if (item[key] === matcher) {
				el.classList.add(...["highlight-note-wr"]);
				uncompleted_notes.push(item);
			} else {
				el.classList.remove("highlight-note-wr");
			}
		}
	});

	if (uncompleted_notes.length) {
		throw new Error(
			key == "label"
				? "app.ntfy.err.apply_labels_to_nodes"
				: "app.ntfy.err.type_text_to_nodes",
			{
				cause: "werCustom",
			},
		);
	}
};

const validateNotesStatus = async (arr) => {
	try {
		loader.start();

		let response = await store.dispatch("whiteboard/getEssentialParams");

		// check if min number of notes added to the board or not
		if (!arr || arr.length < response.min_number_of_question_nodes) {
			throw new Error("app.ntfy.err.min_nodes_required", {
				cause: "werCustom",
			});
		}

		// check if each note is having text inside it or not
		prepareUnCompletedNotes(arr, "text", "");

		// If validation is correct then proceed.
		EventBus.$emit("crq-ready-to-redirect-next");
	} catch (error) {
		announce.error(error);
	} finally {
		loader.stop();
	}
};

const validateTagsPresence = async (arr) => {
	try {
		loader.start();

		// Check for empty notes
		prepareUnCompletedNotes(arr, "text", "");

		// Check for unlabeled notes
		prepareUnCompletedNotes(arr, "label", 0);

		// Count at least two notes should have close and two should have open tag
		let closed_questions = 0;
		let open_questions = 0;

		arr.forEach((item) => {
			item.label === DEFINES.OPEN_QUESTION_VALUE
				? open_questions++
				: closed_questions++;
		});

		if (open_questions <= 1 || closed_questions <= 1) {
			throw new Error("app.ntfy.err.min_tags_required", {
				cause: "werCustom",
			});
		}

		// If validation is correct then proceed.
		EventBus.$emit("crq-ready-to-redirect-next");
	} catch (error) {
		announce.error(error);
	} finally {
		loader.stop();
	}
};

const validateTagsInterchanging = async (input, board_uuid) => {
	try {
		loader.start();

		// If this step is not completed already then only do validate
		if (store.getters["process/isCurrentStepCompleted"]) {
			EventBus.$emit("crq-ready-to-redirect-next");
			return;
		}

		// check if each note is having text inside it or not
		prepareUnCompletedNotes(input, "text", "");

		// Get the data which is at the server
		let response = await store.dispatch("whiteboard/get", {
			uuid: board_uuid,
		});

		let db = response.data;
		let open_to_close = 0;
		let close_to_open = 0;

		// Now compare between server and input(local) data.
		for (let i = 0; i < db.length; i++) {
			// If text has changed of any note then only consider validation
			if (db[i].text !== input[i].text) {
				// If labels does not change then do not modify anything.
				if (db[i].label == input[i].label) continue;

				/**
				 * In db, label = 1, in local, label = -1, which means converted
				 * from open to close else converted from close to open.
				 */
				db[i].label > input[i].label ? open_to_close++ : close_to_open++;
			}
		}

		// If validation is correct then proceed.
		if (open_to_close == 1 && close_to_open == 1) {
			EventBus.$emit("crq-ready-to-redirect-next");
			return;
		}

		// If condition do not matches then throw err to interchange tags.
		throw new Error("app.ntfy.err.min_tags_interchanged_required", {
			cause: "werCustom",
		});
	} catch (error) {
		announce.error(error);
	} finally {
		loader.stop();
	}
};

const validateFavNotes = async (arr) => {
	try {
		loader.start();

		let response = await store.dispatch("whiteboard/getEssentialParams");

		// check if fav notes is equal in length of mentioned in API
		let fav_notes = await filterArray(arr, "fav", false);
		if (fav_notes.length !== response.max_favorites_question_nodes) {
			throw new Error("app.ntfy.err.exact_fav_nodes", {
				cause: "werCustom",
			});
		}

		// If validation is correct then proceed.
		EventBus.$emit("crq-ready-to-redirect-next");
	} catch (error) {
		announce.error(error);
	} finally {
		loader.stop();
	}
};

const isBoardExistAtServer = async (uuid) => {
	try {
		let response = await store.dispatch("whiteboard/get", {
			uuid,
		});
		return Object.keys(response).length;
	} catch (error) {
		announce.error(error);
	}
};

const validateNodesStatus = async (arr, is_first_step, action = "next") => {
	try {
		loader.start();

		let root_node = findFromArray(arr, "is_title_node", true);

		if (!root_node) {
			throw new Error("app.ntfy.err.title_node_required", {
				cause: "werCustom",
			});
		}

		// One root node + 10 other notes
		if (!is_first_step && arr.length < 10) {
			throw new Error(
				i18n.t("app.ntfy.err.min_nodes_except_title_node", {
					total: 10,
				}),
				{
					cause: "werCustom",
				},
			);
		}

		prepareUnCompletedNotes(arr, "text", "");

		action === "back"
			? EventBus.$emit("wmm-redirect-back")
			: EventBus.$emit("wmm-redirect-next");
	} catch (error) {
		announce.error(error);
	} finally {
		loader.stop();
	}
};

const validateFavNodes = async (arr) => {
	try {
		loader.start();

		// check if fav notes is equal in length of mentioned in API
		let fav_notes = await filterArray(arr, "fav", false);
		if (fav_notes.length < 3) {
			throw new Error("app.ntfy.err.min_fav_items", {
				cause: "werCustom",
			});
		}

		// If validation is correct then proceed.
		EventBus.$emit("wmm-finish-work");
	} catch (error) {
		announce.error(error);
	} finally {
		loader.stop();
	}
};

export {
	validateNotesStatus,
	validateTagsPresence,
	validateTagsInterchanging,
	validateFavNotes,
	isBoardExistAtServer,
	validateNodesStatus,
	validateFavNodes,
};
