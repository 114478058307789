<template>
	<v-card v-if="board" height="100%" @dblclick="addNotes" flat>
		<!-- Primary Actions -->
		<AppActionsTheme
			type="primary"
			:actions="primaryActions"
			@handle-function-call="handleFunctionCall"
		>
		</AppActionsTheme>

		<!-- Required Actions -->
		<AppActionsTheme
			v-if="isBoardOwner"
			type="required"
			:actions="requiredActions"
			@handle-function-call="handleFunctionCall"
		>
		</AppActionsTheme>

		<!-- Container to operate on -->
		<v-card flat id="downloadable-container-wr" height="100%">
			<!-- In order to keep nodes position same in all cases, Each parent container
				 of vue resizable should placed at the top left so all nodes (vue resizable) can
				 start their dimensions calculation from top left. (see style of class .resizable)
		 -->
			<vue-resizable
				v-for="(item, index) in board.data"
				:key="index"
				:fitParent="true"
				class="resizable resizable-wr"
				ref="resizableComponent"
				dragSelector=".drag-container-wr"
				:active="[]"
				:fit-parent="false"
				:width="item.width"
				:height="item.height"
				:max-width="250"
				:max-height="250"
				:min-width="150"
				:min-height="150"
				:top="item.top"
				:left="item.left"
				@drag:end="eHandler(item.id, $event)"
			>
				<v-hover v-slot:default="{ hover }">
					<div class="drag-container-wr">
						<!-- Remove note button (only display at 2nd step if 2nd step is incomplete) -->
						<v-icon
							color="error"
							v-if="hover && isAddNotesCurrentStep"
							@click.stop="removeNotes(item.id)"
							class="cursor-wr"
							:style="closeIconStyles"
							>$vuetify.icons.values.cancel</v-icon
						>
						<div
							class="amberAccent1"
							:id="item.id"
							:style="{ height: item.height }"
						>
							<v-textarea
								solo
								flat
								no-resize
								hide-details
								v-model="item.text"
								class="elevation-0"
								background-color="amberAccent1"
								v-debounce:1s="manageTyping(item.id)"
								:placeholder="
									$t('app.placeholder.type_question_here_and_drag', {
										question_no: index + 1,
									})
								"
								:readonly="current_step.name === 'selectFavNotes'"
							></v-textarea>

							<!-- Custom operations (add tags) -->
							<NoteActivity
								:note="item"
								@mark-as-open-question="
									updateNoteLabel($defines.OPEN_QUESTION_VALUE, item)
								"
								@mark-as-closed-question="
									updateNoteLabel($defines.CLOSED_QUESTION_VALUE, item)
								"
								@update-fav-status="updateNoteFavStatus(item)"
							></NoteActivity>
						</div>
					</div>
				</v-hover>
			</vue-resizable>
		</v-card>

		<!-- Sharing Dialog box -->
		<ShareDialog
			v-model="share_dialog"
			:dialog="share_dialog"
			:uuid="board.uuid"
			@close="share_dialog = false"
			@confirm="share_dialog = false"
		></ShareDialog>

		<!-- Confirmation box for delete operation -->
		<TheConfirmation
			v-model="delete_dialog"
			:dialog="delete_dialog"
			@cancel="delete_dialog = false"
			@confirm="executeDeletion()"
		></TheConfirmation>
	</v-card>
</template>

<script>
import firebase from "@/services/firebase";
import { mapGetters, mapState } from "vuex";
import {
	filterArray,
	findFromArray,
	findElIndex,
	generateRandomString,
} from "@/utils/helpers";
import { collaborationCommonInfoMixin } from "@/mixins/collaboration/commonInfoMixin.js";
import { collaborationFirebaseMixin } from "@/mixins/collaboration/firebaseMixin.js";
import NoteActivity from "@/components/research/modules/defineQuestion/stages/chooseQuestion/steps/board/NoteActivity.vue";
import {
	validateNotesStatus,
	validateTagsPresence,
	validateTagsInterchanging,
	validateFavNotes,
	isBoardExistAtServer,
} from "@/utils/helpers/whiteboard";

export default {
	name: "ChooseQuestionBoard",

	mixins: [collaborationCommonInfoMixin, collaborationFirebaseMixin],

	components: {
		NoteActivity,
	},

	created() {
		this.readyToListenEvents();
	},

	computed: {
		...mapGetters({
			isCurrentStepCompleted: "process/isCurrentStepCompleted",
		}),

		isIntroStep() {
			return this.current_step.name === "instructions";
		},

		isAddNotesCurrentStep() {
			return this.current_step.name === "addNotesWithTimer";
		},

		isInterChangeTagsStep() {
			return this.current_step.name === "interchangeTags";
		},

		isSelectFavNoteStep() {
			return this.current_step.name === "selectFavNotes";
		},

		closeIconStyles() {
			return {
				position: "absolute",
				top: 0,
				[this.$vuetify.rtl ? "left" : "right"]: 0,
				zIndex: "1",
				height: "15px",
				width: "15px",
			};
		},

		primaryActions() {
			return [
				{
					icon: "rectangle_outline",
					tooltip: "app.add_notes",
					method: "addNotes",
					condition: this.isAddNotesCurrentStep,
					// && !this.isCurrentStepCompleted,
				},
				// {
				// 	icon: "delete",
				// 	tooltip: "app.delete_board",
				// 	method: "showConfirmationDialog",
				// 	condition: this.isBoardOwner && this.isAddNotesCurrentStep,
				// 	// && !this.isCurrentStepCompleted,
				// },
				{
					icon: "download",
					tooltip: "app.download",
					method: "downloadObject",
					condition: true,
				},
			];
		},
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("crq-validate-notes-status", (payload) => {
				validateNotesStatus(this.board.data);
			});
			this.$eventBus.$on("crq-validate-tags-presence", (payload) => {
				validateTagsPresence(this.board.data);
			});
			this.$eventBus.$on("crq-validate-fav-notes", (payload) => {
				validateFavNotes(this.board.data);
			});
			this.$eventBus.$on("crq-validate-tags-interchanging", async (payload) => {
				validateTagsInterchanging(this.board.data, this.board.uuid);
			});
			this.$eventBus.$on("crq-ready-to-redirect-next", async (payload) => {
				// 1. Update the board at server
				await this.updateAtDatabase();
				// 2. Redirect to the respected step.
				this.$eventBus.$emit("crq-redirect-next");
			});
		},

		/**===================================================
		 * NOTE OPERATIONS METHODS
		 ====================================================*/

		async addNotes(event = null) {
			try {
				this.$loader.start();

				if (
					!this.isAddNotesCurrentStep ||
					(event && event.target.id != "downloadable-container-wr")
				) {
					return;
				}

				// Allow to add notes only if step is 2nd OR clicked on specific target
				let obj = {};
				obj.id = generateRandomString();
				obj.height = 200;
				obj.width = 200;
				obj.left = 700;
				obj.top = 300;
				obj.eventName = "";
				obj.text = "";
				obj.label = 0;
				obj.fav = false;

				// update this at firebase first
				await this.updateAtFirebase("add_node", {
					id: obj.id,
					data: obj,
				});

				// Update at server side
				await this.updateAtDatabase();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async removeNotes(note_id) {
			try {
				this.$loader.start();

				await this.updateAtFirebase("remove_node", {
					id: note_id,
				});

				// Update board
				await this.updateAtDatabase();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async updateNoteLabel(label, note) {
			try {
				this.$loader.start();

				// If already same lable is applied
				if (note.label === label) return;

				// If tags interchanging step then allow only if text has changed
				if (this.isInterChangeTagsStep) {
					let response = await this.$store.dispatch("whiteboard/get", {
						uuid: this.board.uuid,
					});
					let db_note = findFromArray(response.data, "id", note.id);
					if (!note.text || note.text == db_note.text) {
						throw new Error("app.ntfy.err.update_label_without_update_text", {
							cause: "werCustom",
						});
					}
				}

				// Update at firebase first
				await this.updateAtFirebase("label", {
					id: note.id,
					label: label,
				});

				// If step is not interchaning tags then save data to db
				if (!this.isInterChangeTagsStep) {
					await this.updateAtDatabase();
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async updateNoteFavStatus(note) {
			try {
				this.$loader.start();

				// If current step is completed already then do not change fav status
				// if (this.isCurrentStepCompleted) {
				// 	return;
				// }

				// Update at firebase first
				await this.updateAtFirebase("fav", {
					id: note.id,
					fav: !note.fav,
				});

				// Now, set to server side
				await this.updateAtDatabase();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("crq-validate-tags-presence");
		this.$eventBus.$off("crq-validate-notes-status");
		this.$eventBus.$off("crq-validate-tags-interchanging");
		this.$eventBus.$off("crq-validate-fav-notes");
		this.$eventBus.$off("crq-ready-to-redirect-next");
	},
};
</script>

<style scoped>
>>> .v-input--selection-controls .v-input__slot > .v-label {
	color: black !important;
}
</style>
