import firebase from "@/services/firebase";
import { mapState, mapGetters } from "vuex";
import { wizardMindmapSteps } from "@/utils/steps";
import { chooseQuestionBoardSteps } from "@/utils/steps";
import { convertToNum, findFromArray } from "@/utils/helpers";

export const collaborationStepsManageMixin = {
	data() {
		return {
			is_board_owner: false,
		};
	},

	created() {
		this.$eventBus.$on("mutate-steps", async (payload) => {
			this.mutateSteps(payload);
		});
		this.$eventBus.$on("get-and-set-steps", async (payload) => {
			try {
				this.$loader.start();

				this.getAndSetCurrentStep(payload);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		});
		this.$eventBus.$on("set-steps-to-initial", async (payload) => {
			this.saveSteps(payload);
		});
	},

	created() {
		this.init();
	},

	watch: {
		"current_step.name": function(newVal, oldVal) {
			if (newVal) {
				// On every change of current step, update steps at firebase.
				this.updateStepsToFirebase();
			}
		},
	},

	computed: {
		...mapState({
			current_step: (state) => state.process.current_step,
			completed_step: (state) => state.process.completed_step,
			process_status_id: (state) => state.process.process_status.id,
			current_board_id: (state) => state.whiteboard.current_board_id,
			current_module: (state) => state.research.current_module,
			current_stage: (state) => state.research.current_stage,
		}),

		...mapGetters({
			isCurrentStepCompleted: "process/isCurrentStepCompleted",
			userProfile: "user/getProfile",
		}),

		isMindmapBoard() {
			return this.$route.name == "ChooseTopicWizardMindmap";
		},
	},

	methods: {
		async init() {
			try {
				this.$loader.start();
				// Get board owner
				await this.isBoardOwner();
				// Set current step user was working on
				await this.getAndSetCurrentStep();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async isBoardOwner() {
			if (!this.$route.query.uuid) {
				this.is_board_owner = true;
			} else {
				let response = await this.$store.dispatch("whiteboard/get", {
					uuid: this.$route.query.uuid,
				});

				// If board not found
				if (!Object.keys(response).length) {
					throw new Error("app.ntfy.err.invalid_board_id", {
						cause: "werCustom",
					});
				}

				this.is_board_owner = response.user == this.userProfile.id;
			}
		},

		findItem(key, matcher) {
			let arr = this.isMindmapBoard
				? wizardMindmapSteps
				: chooseQuestionBoardSteps;
			return findFromArray(arr, key, matcher);
		},

		destroyClassesBeforeBack() {
			try {
				this.$loader.start();

				let elements = document.querySelectorAll(".highlight-note-wr");
				elements.forEach((el) => {
					el.classList.remove("highlight-note-wr");
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getAndSetCurrentStep() {
			console.log(this.current_module, this.current_stage);
			// Get process status from backend.
			let response = await this.$store.dispatch("process/getProcessStatus", {
				process: "M" + this.current_module,
				step: this.current_stage,
			});

			// Backend response is of type string, so convert to number before use.
			let completed_step = convertToNum(
				response.process_status.user_completed_step,
			);
			let current_step = convertToNum(
				response.process_status.user_current_step,
			);

			// Mutate steps in state, and pass mount param as true to identify request on mount.
			await this.mutateSteps(
				{
					completed_step,
					current_step,
				},
				true,
			);

			// On mount, update steps to firebase
			await this.updateStepsToFirebase();
		},

		async mutateSteps(payload, on_mounted = false) {
			try {
				this.$loader.start();

				// Find current step obj from value
				let current_step = this.findItem("value", payload.current_step);

				/** if completed step at server is 0 that means user
				 * hasn't completed any step yet, so leave completed step
				 * as it is by making an dummy obj.
				 */
				let completed_step =
					payload.completed_step === 0
						? { value: 0 }
						: this.findItem("value", payload.completed_step);

				// If completed step is not found then consider 0th step as completed.
				if (completed_step === undefined) {
					completed_step = this.isMindmapBoard
						? wizardMindmapSteps[0]
						: chooseQuestionBoardSteps[0];
				}

				// If current step is not found then consider 1st step as current.
				if (current_step === undefined) {
					current_step = this.isMindmapBoard
						? wizardMindmapSteps[1]
						: chooseQuestionBoardSteps[1];
				}

				/**
				 * Condition = current_step >= completed_step
				 * So, if current step is less or equal than completed step then set current step
				 * as follows-
				 * 1. current step should be completed step's next step.
				 * 2. current step should be equal to completed step if completed step's next
				 * step is null.
				 * "--------------NOTE----------------------"
				 * This condition is only to check on mount because in case of back
				 * completed would always be greater than current and in case of next this condition
				 * would not happen anyhow.
				 */
				if (on_mounted && current_step.value <= completed_step.value) {
					current_step = this.findItem(
						"name",
						completed_step.next_step
							? completed_step.next_step
							: completed_step.name,
					);
				}

				this.$store.commit("process/SET_CURRENT_AND_COMPLETED_STEP", {
					current_step,
					completed_step,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async saveSteps(payload) {
			// Save to database
			await this.$store.dispatch("process/setProcessStatus", {
				process_status_id: this.process_status_id,
				user_completed_step: payload.completed_step,
				user_current_step: payload.current_step,
			});

			// Save to state
			await this.mutateSteps({
				current_step: payload.current_step,
				completed_step: payload.completed_step,
			});
		},

		async updateStepsToFirebase() {
			// Only owner can update the steps to firebase
			if (this.is_board_owner && this.current_board_id) {
				firebase
					.database()
					.ref(`boards/${this.current_board_id}`)
					.update({
						current_step: this.current_step.value,
						completed_step: this.completed_step.value,
					});
			}
		},

		async redirectBack() {
			try {
				// Remove some unnecessary classes which are applied in current steps.
				this.destroyClassesBeforeBack();

				/**
				 * When going to back-
				 * current_step = Recent step's previous step.
				 * completed_step = no change
				 */
				if (!this.current_step.previous_step) return;

				// If non-owner is requesting then don't proceed
				if (!this.is_board_owner) {
					throw new Error("app.ntfy.err.cannot_proceed", {
						cause: "werCustom",
					});
				}

				let result = this.findItem("name", this.current_step.previous_step);

				let current_step = result.value;
				let completed_step = this.completed_step.value;

				// Save to state
				await this.mutateSteps({
					current_step,
					completed_step,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async redirectNext() {
			try {
				/**
				 * When going to next-
				 * current_step = Recent step's next step.
				 * completed_step = if greater than current then no change otherwise Recent step.
				 */
				if (!this.current_step.next_step) return;

				// If non-owner is requesting then don't proceed
				if (!this.is_board_owner) {
					throw new Error("app.ntfy.err.cannot_proceed", {
						cause: "werCustom",
					});
				}

				let result = this.findItem("name", this.current_step.next_step);

				let current_step = result.value;
				let completed_step =
					this.completed_step.value >= this.current_step.value
						? this.completed_step.value
						: this.current_step.value;

				this.saveSteps({
					current_step,
					completed_step,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},

	beforeDestroy() {
		// The state id should not conflict, so empty it.
		this.$store.commit("whiteboard/SET_CURRENT_BOARD_ID", {
			board_id: null,
		});
		this.$eventBus.$off("get-and-set-steps");
		this.$eventBus.$off("set-steps-to-initial");
		this.$eventBus.$off("mutate-steps");
	},
};
