<template>
	<v-card v-if="research_question" :class="['elevation-4']" tile>
		<v-card-title class="font-weight-bold">
			<v-row no-gutters>
				<v-col>
					{{ $t("app.my_research_questionl") }}
				</v-col>
				<v-col class="text-end">
					<v-tooltip :right="$vuetify.rtl" :left="!$vuetify.rtl">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								fab
								small
								:color="!edit ? 'warning' : 'success'"
								dark
								@click="doEditOrUpdate()"
							>
								<v-icon>{{
									$vuetify.icons.values[edit ? "check" : "edit"]
								}}</v-icon>
							</v-btn>
						</template>
						<span>
							{{ edit ? $t("app.save") : $t("app.edit") | capitalize }}
						</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-card-title>

		<v-card-text class="research-question-wr error--text font-weight-bold">
			<span v-if="!edit">{{ answer }}</span>
			<v-text-field
				v-else
				v-model="answer"
				outlined
				dense
				color="greenAccent2"
				background-color="white"
				:placeholder="$t('app.placeholder.type_answer')"
			></v-text-field>
		</v-card-text>
	</v-card>
</template>

<script>
import { questionnaireHelper } from "@/utils/helpers/questionnaire";

export default {
	name: "ResearchQuestion",

	data() {
		return {
			research_question: null,
			edit: false,
			answer: null,
		};
	},

	mounted() {
		this.getResearchQuestion();
	},

	methods: {
		async getResearchQuestion() {
			try {
				this.$loader.start();

				let response = await questionnaireHelper.getResearchQuestion();
				if (response) {
					this.research_question = response;
					this.answer = response.answer;
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async doEditOrUpdate() {
			try {
				this.$loader.start();

				// Submit only if user has edited or not
				if (this.edit && this.research_question.answer != this.answer) {
					let response = await this.$store.dispatch(
						"questionnaire/submitAnswer",
						{
							questionnaire_id: this.research_question.id,
							answer: this.answer,
						},
					);
					this.answer = response.answer;
				}

				// Toggle button
				this.edit = !this.edit;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
.research-question-wr {
	font-size: 1.125rem !important; /** 18px */
}
</style>
