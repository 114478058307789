<template>
	<div>
		<!-- Input the research question from user -->
		<MyResearchQuestion
			@submit-my-research-question="submitResearchQuestion"
		></MyResearchQuestion>

		<!-- Display fav questions selected by user -->
		<v-card class="wr_grey_1 mt-10 " flat>
			<v-card-text class="greyDarken4--text font-weight-bold subtitle-1">
				{{ $t("app.my_selected_questions") }}
			</v-card-text>

			<v-card-text>
				<v-row no-gutters v-if="notes && notes.length">
					<v-col
						sm="2"
						:class="getNoteMarginClass(index)"
						v-for="(note, index) in notes"
						:key="index"
					>
						<v-card width="200" height="200" class="amberAccent1">
							<!-- Note's text -->
							<v-card-text class="black--text subtitle-1 text-container-wr">
								{{ note.text }}
							</v-card-text>

							<v-row
								class="opacity-background-wr operations-parent-wr pa-2"
								no-gutters
							>
								<!-- Note's labeling -->
								<v-col>
									<AppButton
										:small="true"
										:rounded="false"
										:label="
											note.label == $defines.OPEN_QUESTION_VALUE
												? 'app.open_question'
												: 'app.closed_question'
										"
										:color="
											note.label == $defines.OPEN_QUESTION_VALUE
												? 'info'
												: 'error'
										"
									/>
								</v-col>

								<!-- Note's fav status -->
								<v-col class="text-end">
									<v-icon v-if="note.fav" color="error"
										>$vuetify.icons.values.heart</v-icon
									>
									<v-icon v-else>$vuetify.icons.values.heart_outline</v-icon>
								</v-col>
							</v-row>
						</v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { filterArray } from "@/utils/helpers";
import MyResearchQuestion from "@/components/research/modules/defineQuestion/stages/chooseQuestion/steps/reviewSelectedNotes/MyResearchQuestion.vue";

export default {
	name: "ChooseQuestionReviewSelectedNotes",

	data() {
		return {
			notes: null,
		};
	},

	computed: {
		...mapState({
			current_step: (state) => state.process.current_step,
			completed_step: (state) => state.process.completed_step,
		}),
	},

	components: {
		MyResearchQuestion,
	},

	mounted() {
		this.getFavQuestions();
	},

	methods: {
		async submitResearchQuestion(payload) {
			try {
				// If old answer and new answers are same then return
				if (payload.answer === payload.formated_answer) {
					return;
				}

				await this.$store.dispatch("questionnaire/submitAnswer", {
					questionnaire_id: payload.id,
					answer: payload.answer,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async getFavQuestions() {
			try {
				this.$loader.start();

				let board = await this.getCurrentBoard();

				if (board) {
					// filter selected notes only
					this.notes = filterArray(board.data, "fav", false);
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getCurrentBoard() {
			let response = await this.$store.dispatch("whiteboard/list", {
				category: this.$defines.SYSTEM_CATEGORY,
				type: this.$defines.WHITEBOARD_TYPES.RESEARCH_QUESTION,
			});

			if (!response.length) {
				this.$eventBus.$emit("set-steps-to-initial", {
					completed_step: 0,
					current_step: 1,
				});
				return null;
			}

			return response[0];
		},

		getNoteMarginClass(index) {
			if (index !== 0) {
				return "ms-13";
			}
		},
	},
};
</script>

<style scoped>
.operations-parent-wr {
	position: absolute;
	bottom: 0;
	width: 100%;
}

.text-container-wr {
	max-height: 150px;
	overflow-y: auto;
}
</style>
