<template>
	<v-card v-if="board && current_board_id" flat height="100%" class="wr_grey_1">
		<AppActionsTheme
			type="access"
			:actions="actions"
			@handle-function-call="handleFunctionCall"
		>
		</AppActionsTheme>

		<!-- Show related component -->
		<component
			v-model="board"
			:board="board"
			@on-after-board-delete="onBoardDelete()"
			:is="this.isMindmapBoard ? 'WizardMindmapBoard' : 'ChooseQuestionBoard'"
		></component>

		<!-- Dialog to ask id of another whiteboard to access-->
		<CollaborateBoardDialog
			v-model="dialog"
			:dialog="dialog"
			@close="dialog = false"
			@confirm="AccessInputIdBoard"
		></CollaborateBoardDialog>
	</v-card>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { mapGetters, mapState } from "vuex";
import CollaborateBoardDialog from "@/components/shared/CollaborateBoardDialog";
import WizardMindmapBoard from "@/components/research/modules/defineQuestion/stages/chooseTopic/alternatives/wizardMindmap/Board.vue";
import ChooseQuestionBoard from "@/components/research/modules/defineQuestion/stages/chooseQuestion/steps/board/Index.vue";
import AppActionsTheme from "@/components/ui/AppActionsTheme.vue";

export default {
	name: "WhiteBoard",

	data() {
		return {
			dialog: false,
			board: null,
		};
	},

	components: {
		CollaborateBoardDialog,
		WizardMindmapBoard,
		ChooseQuestionBoard,
		AppActionsTheme,
	},

	computed: {
		...mapGetters({
			userProfile: "user/getProfile",
		}),

		...mapState({
			current_board_id: (state) => state.whiteboard.current_board_id,
			current_step: (state) => state.process.current_step,
			completed_step: (state) => state.process.completed_step,
		}),

		queryUuid() {
			return this.$route.query.uuid;
		},

		isMindmapBoard() {
			// Because only wizard mind map is a board type mind map
			return this.$route.name === "ChooseTopicWizardMindmap";
		},

		isChooseQuestionBoard() {
			return this.$route.name === "ChooseResearchQuestion";
		},

		actions() {
			return [
				{
					icon: "guest",
					tooltip: "app.access_own_whiteboard",
					method: "accessOwnWhiteboard",
					condition: this.queryUuid,
				},
				{
					icon: "group",
					tooltip: "app.access_other_whiteboard",
					method: "openDialog",
					condition: this.board,
				},
			];
		},
	},

	mounted() {
		this.decideBoardToGet(true);
	},

	methods: {
		openDialog() {
			this.dialog = true;
		},

		handleFunctionCall(method_name) {
			try {
				this[method_name]();
			} catch (error) {
				this.$announce.error(error);
			}
		},

		setCurrentBoardId(board_id) {
			this.$store.commit("whiteboard/SET_CURRENT_BOARD_ID", {
				board_id,
			});
		},

		async decideBoardToGet(on_mounted = false) {
			try {
				this.$loader.start();

				/**
				 * If route has query uuid then get board from id
				 * otherwise get own board.
				 */

				this.board = this.queryUuid
					? await this.validateBeforeAccess(this.queryUuid)
					: await this.getBoard(on_mounted);

				if (this.board) {
					// Set board id as current board_id in the global state.
					await this.setCurrentBoardId(this.board.uuid);
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getBoard(on_mounted = false) {
			try {
				this.$loader.start();

				// First, get respected board
				let response = await this.$store.dispatch("whiteboard/list", {
					category: this.$defines.SYSTEM_CATEGORY,
					type: this.isMindmapBoard
						? this.$defines.WHITEBOARD_TYPES.WIZARD_MINDMAP
						: this.$defines.WHITEBOARD_TYPES.RESEARCH_QUESTION,
				});

				// If board not found then throw error.
				if (!response || !response.length) {
					throw new Error("app.ntfy.err.board_not_found", {
						cause: "werCustom",
					});
				}
				/**
				 * Mutate steps only if component not mounted,
				 * because mounted already set the steps.
				 */
				if (!on_mounted) {
					this.$eventBus.$emit("get-and-set-steps");
				}

				return response[0];
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async accessOwnWhiteboard() {
			try {
				this.$loader.start();

				// Empty the board_id from state
				this.$store.commit("whiteboard/SET_CURRENT_BOARD_ID", {
					board_id: null,
				});

				// Push to the same route with no param in query
				this.$router.push({
					name: this.$route.name,
				});

				// Same route pushing won't refresh, so reload the window.
				window.location.reload();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async AccessInputIdBoard(board_id) {
			try {
				await this.validateBeforeAccess(board_id);

				// Push to current route with "uuid" query param.
				this.$router.push({
					name: this.$route.name,
					query: {
						uuid: board_id,
					},
				});

				/**
				 * We are pushing to the same route so page won't refresh but we
				 * need to do it in order to get the board of new query uuid.
				 */
				this.$router.go();
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async validateBeforeAccess(board_id) {
			// Then Check if board exist
			let board_to_be_accessed = await this.$store.dispatch("whiteboard/get", {
				uuid: board_id,
			});
			if (!Object.keys(board_to_be_accessed).length) {
				throw new Error("app.ntfy.err.board_not_found", {
					cause: "werCustom",
				});
			}

			// First check if user is trying to access his own board
			if (
				board_to_be_accessed.user === this.userProfile.id &&
				board_id === this.current_board_id
			) {
				throw new Error("app.ntfy.err.cannot_access_own_board", {
					cause: "werCustom",
				});
			}

			// If allowed for sharing
			if (!board_to_be_accessed.is_sharing_active) {
				throw new Error("app.ntfy.err.not_activated_for_sharing", {
					cause: "werCustom",
				});
			}

			// If board is of another type
			if (
				(this.isMindmapBoard &&
					board_to_be_accessed.type ==
						this.$defines.WHITEBOARD_TYPES.RESEARCH_QUESTION) ||
				(this.isChooseQuestionBoard &&
					board_to_be_accessed.type ==
						this.$defines.WHITEBOARD_TYPES.WIZARD_MINDMAP)
			) {
				throw new Error("app.ntfy.err.invalid_board_id", {
					cause: "werCustom",
				});
			}

			return board_to_be_accessed;
		},

		async onBoardDelete() {
			try {
				this.$loader.start();
				// reset the local state
				this.board = null;
				// set-up new board again
				await this.getBoard();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
