var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.arrows),function(item,index){return _c('span',{key:index},[_c('v-menu',{attrs:{"bottom":"","offset-y":"","max-width":"20","attach":_vm.is_full_screen ? '#arrow-' + item.id : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('drr',{staticStyle:{"z-index":"2"},attrs:{"id":'arrow-' + item.id,"x":item.x,"y":item.y,"w":item.w,"h":item.h,"angle":item.angle,"aspectRatio":true},on:{"dragstop":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleArrowMethods.apply(void 0, [ item.id ].concat( argsArray ))},"resizestop":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleArrowMethods.apply(void 0, [ item.id ].concat( argsArray ))},"rotatestop":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleArrowMethods.apply(void 0, [ item.id ].concat( argsArray ))}}},[_c('v-img',_vm._g(_vm._b({attrs:{"src":require('@/assets/images/down-arrow.png'),"width":"100%","height":"100%"}},'v-img',attrs,false),on))],1)]}}],null,true)},[_c('v-card',{attrs:{"width":"20","height":"25","align":"center"},on:{"click":function($event){return _vm.$emit('remove-arrow', item.id)}}},[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("$vuetify.icons.values.cancel")])],1)],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }