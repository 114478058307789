var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.board)?_c('v-card',{attrs:{"height":"100%","id":"wizard-map-wr"}},[_c('AppActionsTheme',{attrs:{"type":"primary","actions":_vm.primaryActions},on:{"handle-function-call":_vm.handleFunctionCall}}),(_vm.isBoardOwner)?_c('AppActionsTheme',{attrs:{"type":"required","actions":_vm.requiredActions},on:{"handle-function-call":_vm.handleFunctionCall}}):_vm._e(),_c('ZoomController',{attrs:{"el":"#downloadable-container-wr"}}),(_vm.board.data.length)?_c('v-card',{attrs:{"outlined":"","id":"downloadable-container-wr","height":"100%"}},[(_vm.isArrowSteps && _vm.arrows.length)?_c('Arrows',{attrs:{"is_full_screen":_vm.is_full_screen},on:{"arrow-handler":_vm.arrowHandler,"remove-arrow":_vm.removeArrow}}):_vm._e(),_vm._l((_vm.nodes),function(item,index){return _c('vue-resizable',{key:index,ref:"resizableComponent",refInFor:true,staticClass:"resizable resizable-wr",attrs:{"id":("resize-" + (item.id)),"fitParent":true,"dragSelector":".drag-container-wr","active":['r', 'rb', 'b', 'lb', 'l', 'lt', 't', 'rt'],"width":item.width,"height":item.height,"left":item.left,"top":item.top},on:{"resize:move":function($event){return _vm.eHandler(item.id, $event)},"resize:end":function($event){return _vm.eHandler(item.id, $event)},"drag:end":function($event){return _vm.eHandler(item.id, $event)}}},[_c('div',{staticClass:"drag-container-wr"},[_c('v-menu',{attrs:{"offset-y":"","attach":_vm.is_full_screen ? ("#resize-" + (item.id)) : false,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var _obj;

var on = ref.on;
var attrs = ref.attrs;return [_c('div',{attrs:{"id":item.id}},[_c('v-textarea',_vm._g(_vm._b({directives:[{name:"debounce",rawName:"v-debounce:1s",value:(_vm.manageTyping(item.id)),expression:"manageTyping(item.id)",arg:"1s"}],class:[
									( _obj = {
										'elevation-0': true,
										'font-weight-bold': item.bold,
										'font-italic': item.italic
									}, _obj[item.font] = true, _obj ) ],attrs:{"dark":"","solo":"","flat":"","no-resize":"","hide-details":"","height":item.height,"color":item.color,"background-color":item.background,"placeholder":item.is_title_node
										? _vm.$t('app.placeholder.type_title_here')
										: _vm.$t('app.placeholder.type_text_here_and_drag', {
												box_no: index + 1,
										  })},model:{value:(item.text),callback:function ($$v) {_vm.$set(item, "text", $$v)},expression:"item.text"}},'v-textarea',attrs,false),on),[(
										_vm.current_step && _vm.current_step.name === 'selectFavNodes'
									)?_c('v-icon',{attrs:{"slot":"append","color":item.fav
											? 'error'
											: item.background === 'black'
											? 'white'
											: 'black'},on:{"click":function($event){return _vm.updateStyle(item, {
											attribute: 'fav',
											value: !item.fav,
										})}},slot:"append"},[_vm._v(_vm._s(_vm.$vuetify.icons.values[ item.fav ? "heart" : "heart_outline" ])+" ")]):_vm._e()],1)],1)]}}],null,true)},[_c('Editor',{attrs:{"item":item,"is_full_screen":_vm.is_full_screen},on:{"delete-node":function($event){return _vm.deleteNode(item.id)},"update-style":function($event){return _vm.updateStyle(item, $event)},"prepare-attachment-process":function($event){return _vm.prepareAttachmentProcess(item)}}})],1)],1)])})],2):_vm._e(),_c('AttachmentDialog',{attrs:{"dialog":_vm.attachment_dialog,"node":_vm.selected_node,"attach":"#wizard-map-wr"},on:{"close":function($event){_vm.attachment_dialog = false},"confirm":_vm.setAttachment},model:{value:(_vm.attachment_dialog),callback:function ($$v) {_vm.attachment_dialog=$$v},expression:"attachment_dialog"}}),_c('ShareDialog',{attrs:{"dialog":_vm.share_dialog,"uuid":_vm.board.uuid},on:{"close":function($event){_vm.share_dialog = false},"confirm":function($event){_vm.share_dialog = false}},model:{value:(_vm.share_dialog),callback:function ($$v) {_vm.share_dialog=$$v},expression:"share_dialog"}}),_c('TheConfirmation',{attrs:{"dialog":_vm.delete_dialog},on:{"cancel":function($event){_vm.delete_dialog = false},"confirm":function($event){return _vm.executeDeletion()}},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }