import TheConfirmation from "@/components/layout/TheConfirmation";
import AppActionsTheme from "@/components/ui/AppActionsTheme.vue";
import ShareDialog from "@/components/shared/ShareDialog.vue";
import { findElIndex, isArrayHavingItem } from "@/utils/helpers";
import { mapState, mapGetters } from "vuex";
import { downloadImage } from "@/plugins/htmlToImage";

export const collaborationCommonInfoMixin = {
	props: {
		board: {
			required: true,
			type: Object,
		},
	},

	data() {
		return {
			share_dialog: false,
			delete_dialog: false,
		};
	},

	components: {
		TheConfirmation,
		AppActionsTheme,
		ShareDialog,
	},

	computed: {
		...mapState({
			current_step: (state) => state.process.current_step,
			completed_step: (state) => state.process.completed_step,
		}),

		...mapGetters({
			userProfile: "user/getProfile",
		}),

		isMindmapBoard() {
			return this.$route.name == "ChooseTopicWizardMindmap";
		},

		isBoardOwner() {
			return this.board.user === this.userProfile.id;
		},

		requiredActions() {
			return [
				{
					icon: this.board.is_sharing_active ? "account_check" : "account_off",
					tooltip: this.board.is_sharing_active
						? "app.make_sharing_inactive"
						: "app.make_sharing_active",
					method: "updateSharingStatus",
				},
				{
					icon: "share",
					tooltip: "app.share",
					method: "openSharingDialog",
				},
			];
		},
	},

	created() {
		this.init();
	},

	methods: {
		handleFunctionCall(method) {
			this[method]();
		},

		openSharingDialog() {
			this.share_dialog = true;
		},

		showConfirmationDialog() {
			this.delete_dialog = true;
		},

		async init() {
			try {
				this.$loader.start();

				// Then, set up the firebase listener.
				await this.setUpFirebaseListener();

				// Then apply some styles manually
				if (this.isMindmapBoard) {
					this.applyStylesManually();
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		// VUE DRAG AND DROP PACKAGE METHOD - eHandler
		async eHandler(piece_id, event) {
			try {
				// Update only if end event happens
				if (isArrayHavingItem(["resize:end", "drag:end"], event.eventName)) {
					await this.updateAtFirebase("positions", {
						id: piece_id,
						top: event.top,
						left: event.left,
						width: event.width,
						height: event.height,
					});
				}

				// Save this to server also if step is not tags interchanging
				if (this.current_step.name !== "interchangeTags") {
					this.updateAtDatabase();
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async updateAtDatabase() {
			await this.$store.dispatch("whiteboard/update", this.board);
		},

		async updateSharingStatus(status) {
			try {
				this.$loader.start();

				this.board.is_sharing_active = !this.board.is_sharing_active;

				// Update board's status at firebase
				await this.updateAtFirebase("sharing_status", {
					is_sharing_active: this.board.is_sharing_active,
				});

				// then update at server side
				await this.updateAtDatabase();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		manageTyping: function(piece_id) {
			let _this = this;
			return function(text) {
				let el_index = findElIndex(_this.board.data, "id", piece_id);
				if (el_index == -1) return;

				_this.updateAtFirebase("text", {
					id: piece_id,
					text,
				});

				// Save this to server also
				if (_this.current_step.name !== "interchangeTags") {
					_this.updateAtDatabase();
				}
			};
		},

		async downloadObject() {
			try {
				this.$loader.start();

				// Reset the scale of container first
				let el = document.getElementById("downloadable-container-wr");
				if (!el) return;

				el.style.transform = "scale(1)";

				setTimeout(async () => {
					await downloadImage("#downloadable-container-wr");

					this.$loader.stop();
				}, 1000);
			} catch (error) {
				this.$announce.error(error);
				this.$loader.stop();
			}
		},

		async executeDeletion() {
			// Delete from server
			await this.$store.dispatch("whiteboard/delete", {
				uuid: this.board.uuid,
			});

			/**
			 * Why to empty input.data?
			 * We have setup all listeners on input's data so if we remove input itself
			 * that means all data item will also be removed and data listeners will be
			 * active, but we don't want to listen data(child) if parent is about to remove, so
			 * empty the data(child) so data listeners can not do anything.
			 */
			if (this.board) {
				this.board.data = [];
			}

			// Delete from firebase
			await this.deleteFromFirebase();

			// On after board delete
			await this.resetUpBoard();

			// Close thie dialog
			this.delete_dialog = false;
		},

		async resetUpBoard(flag = null) {
			// delete board id from global state
			this.$store.commit("whiteboard/SET_CURRENT_BOARD_ID", {
				board_id: null,
			});

			// If mind map board has deleted then delete all arrows too.
			if (this.$route.name == "ChooseTopicWizardMindmap") {
				this.$store.commit("mindmap/RESET_ARROWS");
			}

			// If board owner deleted the board then set the steps to initial.
			if (this.isBoardOwner) {
				await this.$eventBus.$emit("set-steps-to-initial", {
					completed_step: 0,
					current_step: 1,
				});
			}
			// Else, throw error to non-owner about delete or disabled activity
			else {
				this.$announce.error(
					new Error(
						flag === "deleted"
							? "app.ntfy.err.owner_deleted_board"
							: flag === "disabled"
							? "app.ntfy.err.owner_disabled_board"
							: "app.ntfy.err.generic",
						{
							cause: "werCustom",
						},
					),
				);

				// Push non-owner to the same route without using any query in param.
				this.$router.push({
					name: this.$route.name,
				});
			}

			// Dispatch event to parent component
			this.$emit("on-after-board-delete");
		},
	},
};
