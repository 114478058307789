<template>
	<v-row no-gutters>
		<span v-for="(item, index) in arrows" :key="index">
			<v-menu
				bottom
				offset-y
				max-width="20"
				:attach="is_full_screen ? '#arrow-' + item.id : false"
			>
				<template v-slot:activator="{ on, attrs }">
					<drr
						:id="'arrow-' + item.id"
						:x="item.x"
						:y="item.y"
						:w="item.w"
						:h="item.h"
						:angle="item.angle"
						:aspectRatio="true"
						style="z-index:2"
						@dragstop="handleArrowMethods(item.id, ...arguments)"
						@resizestop="handleArrowMethods(item.id, ...arguments)"
						@rotatestop="handleArrowMethods(item.id, ...arguments)"
					>
						<v-img
							v-bind="attrs"
							v-on="on"
							:src="require('@/assets/images/down-arrow.png')"
							width="100%"
							height="100%"
						/>
					</drr>
				</template>
				<v-card
					width="20"
					height="25"
					align="center"
					@click="$emit('remove-arrow', item.id)"
				>
					<v-icon small color="error">$vuetify.icons.values.cancel</v-icon>
				</v-card>
			</v-menu>
		</span>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "ChooseTopicAltWizardMindmapArrows",

	props: {
		is_full_screen: {
			type: Boolean,
		},
	},

	computed: {
		...mapState({
			arrows: (state) => state.mindmap.arrows,
		}),
	},

	methods: {
		handleArrowMethods(arrow_id, event) {
			this.$emit("arrow-handler", {
				arrow_id,
				event,
			});
		},
	},
};
</script>
