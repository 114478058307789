import firebase from "@/services/firebase";
import { findElIndex, findFromArray } from "@/utils/helpers";

const dbRef = firebase.database().ref("boards");

export const collaborationFirebaseMixin = {
	methods: {
		async setUpFirebaseListener() {
			if (!this.board) return;

			// Write the input to firebase only if user is board owner
			if (this.isBoardOwner) {
				await this.writeToFirebase();
			}

			/**---------------------------------------
			 * Listener On Parent
			 ----------------------------------------*/

			dbRef.child(this.board.uuid).on("value", async (snapshot) => {
				let fb_response = snapshot.val();

				// Non owner will use the fb_response to access the board
				if (!this.isBoardOwner) {
					// If owner deleted the board
					if (!fb_response) {
						return await this.resetUpBoard("deleted");
					}

					// If owner disabled the board sharing
					if (!fb_response.is_sharing_active) {
						return await this.resetUpBoard("disabled");
					}

					// Use steps at firebase to move back and forth
					this.$eventBus.$emit("mutate-steps", {
						current_step: fb_response.current_step,
						completed_step: fb_response.completed_step,
					});
				}
			});

			/**---------------------------------------
			 * Listener On Child Addition Operation
			 ----------------------------------------*/
			dbRef.child(`${this.board.uuid}/data`).on("child_added", async (data) => {
				let result = findFromArray(this.board.data, "id", data.val().id);

				// Push only if not exist already
				if (!result) {
					this.board.data.push(data.val());
				}
			});

			/**---------------------------------------
			 * Listener On Child Removal Operation
			 ----------------------------------------*/
			dbRef
				.child(`${this.board.uuid}/data`)
				.on("child_removed", async (data) => {
					let el_index = findElIndex(this.board.data, "id", data.val().id);
					if (el_index == -1) return;

					this.board.data.splice(el_index, 1);
				});

			/**---------------------------------------
			 * Listener On Child Updating Operation
			 ----------------------------------------*/
			dbRef
				.child(`${this.board.uuid}/data`)
				.on("child_changed", async (data) => {
					let el_index = findElIndex(this.board.data, "id", data.val().id);
					if (el_index == -1) return;

					this.$set(this.board.data, el_index, data.val());
				});
		},

		async updateAtFirebase(flag, payload) {
			switch (flag) {
				case "sharing_status":
					dbRef.child(this.board.uuid).update({
						is_sharing_active: payload.is_sharing_active,
					});
					break;
				case "positions":
					await dbRef.child(`${this.board.uuid}/data/${payload.id}`).update({
						top: payload.top,
						left: payload.left,
						width: payload.width,
						height: payload.height,
					});
					break;
				case "add_node":
					await dbRef
						.child(`${this.board.uuid}/data/${payload.id}`)
						.set(payload.data);
					break;
				case "remove_node":
					await dbRef.child(`${this.board.uuid}/data/${payload.id}`).remove();
					break;
				default:
					dbRef.child(`${this.board.uuid}/data/${payload.id}`).update({
						[flag]: payload[flag],
					});
					break;
			}

			// update timestamp after any operation
			await dbRef.child(this.board.uuid).update({
				fb_updated_at: new Date().toISOString(),
			});
		},

		async writeToFirebase() {
			try {
				// Set current timestamp when write to firebase
				this.board.fb_updated_at = new Date().toISOString();

				if (this.current_step) {
					this.board.current_step = this.current_step.value;
				}

				if (this.completed_step) {
					this.board.completed_step = this.completed_step.value;
				}

				// first, omit the data key because this needs to be save in a diff way.
				const { data: omitted, ...rest_data } = this.board;

				// second, save the rest data.
				dbRef.child(this.board.uuid).set(rest_data);

				// third, save each item of data array on itself's id
				this.board.data.forEach((piece) => {
					dbRef.child(`${this.board.uuid}/data/${piece.id}`).set(piece);
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async deleteFromFirebase() {
			await dbRef.child(this.board.uuid).remove();
		},
	},
};
