<template>
	<div v-if="data.length">
		<!-- Heading -->
		<v-row no-gutters :class="questionLabelClassObj">
			{{ $t(label) }}
		</v-row>

		<!-- Wirite pros and cons here -->
		<v-row
			:class="[index == 0 ? '' : 'mt-10']"
			no-gutters
			v-for="(questionnaire, index) in data"
			:key="index"
		>
			<!-- Icon -->
			<v-col sm="1">
				<v-container fill-height fluid>
					<v-row no-gutters>
						<v-col>
							<v-icon x-large color="wr_blue_1">{{
								$vuetify.icons.values[
									questionnaire.question.order.toString()[0] == 1
										? "plus"
										: "minus"
								]
							}}</v-icon>
						</v-col>
					</v-row>
				</v-container>
			</v-col>

			<!-- Text field -->
			<v-col>
				<v-card height="250" width="550" class="elevation-5">
					<v-form :ref="'form-' + questionnaire.id">
						<v-textarea
							height="250"
							solo
							flat
							no-resize
							color="greenAccent2"
							background-color="white"
							v-model="questionnaire.answer"
							:rules="
								questionnaire.question.required === 'true'
									? [rules.required]
									: []
							"
							@blur="validateAndProceed(questionnaire)"
							:placeholder="
								questionnaire.question.order.toString()[0] == 1
									? $t('app.placeholder.write_pros_here')
									: $t('app.placeholder.write_cons_here')
							"
						></v-textarea>
					</v-form>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import rules from "@/utils/validation";
import { mapGetters } from "vuex";

export default {
	name: "ProsAndConsQuestionnaireUI",

	data() {
		return {
			rules,
		};
	},

	props: {
		data: {
			required: true,
			type: Array,
		},
		label: {
			required: true,
			type: String,
		},
		type: {
			required: true,
			type: Number,
		},
	},

	computed: {
		...mapGetters({
			isCurrentStepCompleted: "process/isCurrentStepCompleted",
		}),

		questionLabelClassObj() {
			return [
				"font-weight-bold",
				"wr_blue_1--text",
				"subtitle-1",
				"mb-5",
				"ms-15",
			];
		},
	},

	methods: {
		validateAndProceed(questionnaire) {
			try {
				if (!this.$refs["form-" + questionnaire.id][0].validate()) {
					throw new Error("app.ntfy.err.fill_required_fields", {
						cause: "werCustom",
					});
				}

				this.$emit("submit-pros-cons-questionnaire", questionnaire);
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
