<template>
	<v-card flat height="100%" class="wr_grey_1" v-if="questionnaire">
		<v-row no-gutters>
			<!-- Open Questionnaire -->
			<v-col>
				<QuestionnaireUI
					v-if="openQuestionnaire"
					:data="openQuestionnaire"
					label="app.open_questions"
					:type="$defines.OPEN_QUESTION_VALUE"
					@submit-pros-cons-questionnaire="submitQuestionnaire"
				></QuestionnaireUI>
			</v-col>

			<!-- Closed Questionnaire -->
			<v-col>
				<QuestionnaireUI
					v-if="closedQuestionnaire"
					:data="closedQuestionnaire"
					:type="$defines.CLOSED_QUESTION_VALUE"
					label="app.closed_questions"
					@submit-pros-cons-questionnaire="submitQuestionnaire"
				></QuestionnaireUI>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import QuestionnaireUI from "./QuestionnaireUI";

export default {
	name: "ChooseQuestionWriteProsAndCons",

	created() {
		this.readyToListenEvents();
	},

	mounted() {
		this.getQuestionnaire();
	},

	components: {
		QuestionnaireUI,
	},

	computed: {
		...mapState({
			questionnaire: (state) => state.questionnaire.list,
			completed_step: (state) => state.process.completed_step,
			current_step: (state) => state.process.current_step,
		}),

		openQuestionnaire() {
			// 1st column's data is open questionnaire's data.
			return this.questionnaire.answers.filter((answer) => {
				// Order format = [row][column] = 21
				return answer.question.order.toString()[1] == 1;
			});
		},

		closedQuestionnaire() {
			// 2nd column's data is closed questionnaire's data.
			return this.questionnaire.answers.filter((answer) => {
				// Order format = [row][column] = 22
				return answer.question.order.toString()[1] == 2;
			});
		},
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("crq-validate-pros-and-cons", async (payload) => {
				try {
					this.$loader.start();

					// check if all required questions has been answered or not
					let is_valid = await this.isAllRequiredFieldAnswered();
					if (!is_valid) {
						throw new Error("app.ntfy.err.fill_required_fields", {
							cause: "werCustom",
						});
					}

					// If answered then proceed to next step
					this.$eventBus.$emit("crq-redirect-next");
				} catch (error) {
					this.$announce.error(error);
				} finally {
					this.$loader.stop();
				}
			});
		},

		async getQuestionnaire() {
			try {
				this.$loader.start();

				await this.$store.dispatch("questionnaire/list", {
					questionnaire_name: "m2_open_close_questions_p10",
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		isAllRequiredFieldAnswered() {
			// Check how many required questions are left unanswered
			let response = this.questionnaire.answers.filter((item) => {
				return item.question.required === "true" && !item.answer;
			});

			return response.length ? false : true;
		},

		async submitQuestionnaire(payload) {
			try {
				// If old answer and new answers are same then return
				if (payload.answer === payload.formated_answer) {
					return;
				}

				await this.$store.dispatch("questionnaire/submitAnswer", {
					questionnaire_id: payload.id,
					answer: payload.answer,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("crq-validate-pros-and-cons");
	},
};
</script>
