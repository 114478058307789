<template>
	<v-card flat class="wr_grey_1">
		<!-- Intro to understand the stage (show only at first step) -->
		<StageIntroDialog/>

		<v-row no-gutters class="info-header-row-wr">
			<v-col offset-lg="3">
				<QuestionFocus></QuestionFocus>
			</v-col>

			<v-col v-if="current_step">
				<Timer :time_limit="10"></Timer>
			</v-col>

			<!-- Show only for first two steps. -->
			<v-col v-if="current_step && current_step.value < 3">
				<AppButton
					label="app.help"
					icon="info_circle_filled"
					@click="instructions_dialog = true"
				></AppButton>
			</v-col>
		</v-row>

		<!-- Respected component of respected steps -->
		<component
			v-if="displayCondition"
			:is="current_step.component_name"
		></component>

		<!-- At non-board steps, the non-owner will see this dialog. -->
		<BoardOwnerBusyDialog
			v-model="owner_busy_dialog"
			:dialog="owner_busy_dialog"
		></BoardOwnerBusyDialog>

		<Instructions
			v-if="instructions_dialog && plan.actual_start_date"
			v-model="instructions_dialog"
			:dialog="instructions_dialog"
			@close="instructions_dialog = false"
		></Instructions>
	</v-card>
</template>

<script>
import Timer from "@/components/research/modules/defineQuestion/stages/chooseQuestion/Timer.vue";
import StageIntroDialog from "@/components/research/modules/_common/stages/IntroDialog";
import BoardOwnerBusyDialog from "@/components/research/modules/defineQuestion/stages/chooseQuestion/BoardOwnerBusyDialog.vue";
import { collaborationStepsManageMixin } from "@/mixins/collaboration/stepsManageMixin.js";
import { isArrayHavingItem } from "@/utils/helpers";
import QuestionFocus from "@/components/research/modules/defineQuestion/stages/chooseQuestion/QuestionFocus.vue";
import * as boardStepComponents from "@/components/research/modules/defineQuestion/stages/chooseQuestion/steps/index.js";
import { v4 as uuidv4 } from "uuid";
import { mapState } from "vuex";

export default {
	name: "ChooseQuestionPage",

	mixins: [collaborationStepsManageMixin],

	data() {
		return {
			owner_busy_dialog: false,
			instructions_dialog: false,
			collaborative_steps: [
				"addNotesWithTimer",
				"chooseTagsForNotes",
				"interchangeTags",
				"selectFavNotes",
			],
			board_created: false,
		};
	},

	components: {
		Timer,
		StageIntroDialog,
		BoardOwnerBusyDialog,
		QuestionFocus,
		...boardStepComponents,
	},

	created() {
		this.readyToListenEvents();
		this.callRequiredFunctionsInOrder();
	},

	watch: {
		"current_step.name": function(newVal, oldVal) {
			if (newVal) {
				// On every step's change
				this.shouldOpenNonAccessDialog();
			}
		},

		current_step(newVal) {
			// Check if current step is available
			if (newVal) {
				// Show dialog only if user's current step is one and not completed.
				this.instructions_dialog =
					this.current_step.value === 1 &&
					this.completed_step.value < this.current_step.value;
			}
		},
	},

	computed: {
		...mapState({
			current_stage: (state) => state.research.current_stage,
		}),

		plan() {
			return this.$store.getters["planAndManage/getStagePlan"](
				this.current_stage,
			);
		},

		displayCondition() {
			// 1. Current step is necessary
			if (!this.current_step) return false;
			// 2. for whiteboard steps, board creation is necessary
			if (this.current_step.component_name === "WhiteBoard") {
				return this.board_created;
			}
			// 3. Else all is true
			return true;
		},
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("crq-redirect-back", async (payload) => {
				this.redirectBack();
			});
			this.$eventBus.$on("crq-redirect-next", async (payload) => {
				this.redirectNext();
			});
			this.$eventBus.$on("crq-save-steps", async (payload) => {
				this.saveSteps(payload);
			});
		},

		async callRequiredFunctionsInOrder() {
			try {
				this.$loader.start();

				await this.isBoardExists();

				await this.shouldOpenNonAccessDialog();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async shouldOpenNonAccessDialog() {
			// For non-owner, non-collaborative steps should hide behind the dialog
			this.owner_busy_dialog =
				!this.is_board_owner &&
				!isArrayHavingItem(this.collaborative_steps, this.current_step.name);
		},

		async isBoardExists() {
			let response = await this.$store.dispatch("whiteboard/list", {
				category: this.$defines.SYSTEM_CATEGORY,
				type: this.$defines.WHITEBOARD_TYPES.RESEARCH_QUESTION,
			});

			// IF BOARD NOT FOUND IN DATABASE
			if (!response || !response.length) {
				// Create the board
				await this.$store.dispatch("whiteboard/create", {
					uuid: uuidv4(),
					category: this.$defines.SYSTEM_CATEGORY,
					type: this.$defines.WHITEBOARD_TYPES.RESEARCH_QUESTION,
				});

				// Reset steps
				this.$eventBus.$emit("set-steps-to-initial", {
					current_step: 1,
					completed_step: 0,
				});
			}

			this.board_created = true;
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("crq-redirect-back");
		this.$eventBus.$off("crq-redirect-next");
		this.$eventBus.$off("crq-save-steps");
	},
};
</script>

<style scoped>
.info-header-row-wr {
	position: absolute;
	z-index: 2;
	width: 97%;
}
</style>
