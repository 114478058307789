import { render, staticRenderFns } from "./Board.vue?vue&type=template&id=7b6bc712&scoped=true&"
import script from "./Board.vue?vue&type=script&lang=js&"
export * from "./Board.vue?vue&type=script&lang=js&"
import style0 from "./Board.vue?vue&type=style&index=0&id=7b6bc712&scoped=true&lang=css&"
import style1 from "./Board.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b6bc712",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCard,VIcon,VMenu,VTextarea})
